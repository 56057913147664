import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { DataGrid } from '@mui/x-data-grid';

import useAxiosPrivate from '../../../hooks/useAxiosPrivate';

const columns = [
  {
    headerName: 'Property',
    field: 'propertyTitle',
    sortable: true,
    width: 200
  },
  {
    headerName: 'Unit',
    field: 'title',
    sortable: true,
  },
];

function Units() {
  const [units, setUnits] = useState([]);
  const axiosPrivate = useAxiosPrivate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axiosPrivate.get('/api/admin/units');
        setUnits(response.data);
      } catch (error) {
        console.log('error: ', error);
      }
    };

    fetchData();
  }, [axiosPrivate]);

  return (
    <Container>
      <h1>Units</h1>
      {units.length > 0 ? (
        <DataGrid columns={columns} rows={units} />
      ) : (
        <p>No units to display</p>
      )}
    </Container>
  );
}

const Container = styled.div`
  height: calc(100vh - 200px);
`;

export default Units;
