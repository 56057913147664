// External Imports
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { Button, Step, Stepper, StepLabel } from '@mui/material';

// Internal Imports
import serverRequest from '../../api/axios';
import ApplicationSections from './ApplicationSections';
import {
  applicationSectionData,
  getInitialLocalState,
  saveFormStateToLocalStorage,
  validateApplication,
} from './newTenantApplicationsUtils';

function NewTenantApplication() {
  const navigate = useNavigate();
  const [activeStep, setActiveStep] = React.useState(0);
  const [formState, setFormState] = useState(getInitialLocalState());
  const [formErrors, setFormErrors] = useState({});

  const onFinalSection = activeStep === applicationSectionData.length - 1;

  const handleFormChange = (e, switchValue) => {
    if (typeof e?.preventDefault === 'function') {
      e?.preventDefault();
    }
    const { name, type, value } = e?.target ?? {};

    // console.log('name: ', name);
    // console.log('type: ', type);
    // console.log('switchValue: ', switchValue);

    // FIXME: checkbox double click
    if (type === 'checkbox' || type === 'switch') {
      setFormState({ ...formState, [name]: switchValue || false });
      setFormErrors({ ...formErrors, [name]: null });
    } else {
      setFormState({ ...formState, [name]: value });
      setFormErrors({ ...formErrors, [name]: null });
    }
  };

  const handleFormSubmit = async (body) => {
    try {
      await serverRequest.post('/api/tenant/application', body);
      navigate('/tenant');
    } catch (error) {
      console.log('error: ', error);
    }
  };

  const handleNext = async () => {
    const body = validateApplication(
      formState,
      applicationSectionData[activeStep].requiredFields
    );

    console.log('body: ', body);
    console.log('body.errors: ', body.errors);

    if (body.errors) {
      setFormErrors(body.errors);
    } else {
      if (onFinalSection) {
        handleFormSubmit(body);
      } else {
        setActiveStep(activeStep + 1);
      }
    }
  };

  const handleBack = () => {
    setActiveStep(activeStep - 1);
  };

  const handleFileChange = (file) => {
    setFormState({ ...formState, idImg: file });
  };

  useEffect(() => {
    saveFormStateToLocalStorage(formState);
  }, [formState]);

  const ApplicationStep = ApplicationSections[activeStep];

  return (
    <Container>
      <h1>Northbound Properties: Tenant Application</h1>
      <Stepper
        activeStep={activeStep}
        orientation="horizontal"
        sx={{
          marginBottom: '24px',
        }}
      >
        {applicationSectionData.map((section) => (
          <Step key={section.title}>
            <StepLabel>{section.title}</StepLabel>
          </Step>
        ))}
      </Stepper>
      <FormContainer>
        <ApplicationStep
          applicationState={formState}
          formErrors={formErrors}
          handleFormChange={handleFormChange}
          handleFileChange={handleFileChange}
        />
      </FormContainer>
      <FormButtonBar>
        <Button onClick={handleBack} disabled={activeStep < 1} tabIndex={0}>
          Back
        </Button>
        {onFinalSection ? (
          <Button
            onClick={handleNext}
            variant="contained"
            disabled={!formState.agree}
          >
            Submit Application
          </Button>
        ) : (
          <Button onClick={handleNext} variant="contained">
            Next
          </Button>
        )}
      </FormButtonBar>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 24px 5%;
  height: 100%;

  h1 {
    text-align: center;
    margin-bottom: 24px;
  }
`;

const FormContainer = styled.div`
  min-width: 800px;
  max-width: 1200px;
  min-height: 565px;
  padding: 6px 12px 40px 12px;
  overflow-y: scroll;
  display: flex;
  justify-content: center;
`;

const FormButtonBar = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 12px;
`;

export default NewTenantApplication;
