import axios from '../api/axios';
import useAuth from './useAuth';

const IS_DEV = process.env.REACT_APP_DEV === 'true';
const REFRESH_PATH = '/auth/refresh';

const useRefreshToken = () => {
  const { auth, setAuth } = useAuth();

  const refresh = async () => {
    if (IS_DEV) {
      const path = `${REFRESH_PATH}?refreshToken=${auth.refreshToken}`;
      const response = await axios.get(path, {
        withCredentials: true,
      });
      const { accessToken, refreshToken } = response.data;

      localStorage.setItem('jwt', refreshToken);

      setAuth((prev) => {
        return { ...prev, accessToken, refreshToken };
      });

      return accessToken;
    } else {
      const response = await axios.get(REFRESH_PATH, {
        withCredentials: true,
      });
      const { accessToken } = response.data;

      setAuth((prev) => {
        return { ...prev, accessToken };
      });

      return accessToken;
    }
  };

  return refresh;
};

export default useRefreshToken;
