import Contracts from './Contracts';
import Dashboard from './Dashboard';
import Properties from './Properties';
import Units from './Units';

const exports = {
  Contracts,
  Dashboard,
  Properties,
  Units,
};

export default exports;
