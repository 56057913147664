import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

function SideBar({ collapsed }) {
  return (
    <Container collapsed={collapsed}>
      <StyledLink to="/admin">Dashboard</StyledLink>
      <StyledLink to="/admin/accounts">Accounts</StyledLink>
      <StyledLink to="/admin/applications">Applications</StyledLink>
      <StyledLink to="/admin/contracts">Contracts</StyledLink>
      <StyledLink to="/admin/properties">Properties</StyledLink>
      <StyledLink to="/admin/users">Users</StyledLink>
    </Container>
  );
}

const Container = styled.nav`
  width: ${({ collapsed }) => (collapsed ? '50px' : '250px')};
  max-width: 300px;
  height: 100%;
  padding: 12px;
  background-color: #111;
  color: white;
  display: flex;
  flex-direction: column;
  font-weight: bold;
  font-size: 18px;
`;

const StyledLink = styled(Link)`
  border-radius: 4px;
  padding: 8px;
  margin-bottom: 8px;

  color: white;
  text-decoration: none;

  &:hover {
    background-color: #222;
  }
`;

export default SideBar;
