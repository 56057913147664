import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';

// MUI imports
import { DataGrid } from '@mui/x-data-grid';
import { Box, Button, Modal } from '@mui/material';

import useAxiosPrivate from '../../../hooks/useAxiosPrivate';
import { transformPropertyData, unitCols } from './propertiesUtils';
import AddEditUnit from './Modals/AddEditUnit';

const fetchProperty = async (axiosPrivate, id, setProperty, setLoading) => {
  try {
    const property = await axiosPrivate.get(`/api/admin/properties/${id}`);

    setProperty(transformPropertyData(property.data));
    setLoading(false);
  } catch (error) {
    setLoading(false);
    console.log('error: ', error);
  }
};

function Property() {
  const [loading, setLoading] = useState(true);
  const [modalOpen, setModalOpen] = useState(false);
  const [property, setProperty] = useState();
  const [selectedUnits, setSelectedUnits] = useState([]);
  const [unitState, setUnitState] = useState(null);

  const openModal = () => setModalOpen(true);
  const closeModal = () => setModalOpen(false);

  const { id } = useParams();
  const axiosPrivate = useAxiosPrivate();

  useEffect(() => {
    fetchProperty(axiosPrivate, id, setProperty, setLoading);
  }, [axiosPrivate, id]);

  const selectUnit = (selection) => {
    setSelectedUnits(selection);
  };

  const editUnit = () => {
    const [selected] = selectedUnits;
    console.log('selectedUnits: ', selectedUnits);
    const unit = units.find((unit) => unit.id === selected);
    setUnitState(unit);
    setModalOpen(true);
  };

  const deleteUnits = async () => {
    console.log(selectedUnits);
  };

  const {
    address_line_1,
    address_line_2,
    city,
    state,
    zip,
    owner_user,
    units,
    vacancyRate,
    totalUnitCount,
  } = property || {};

  const selectedUnitCount = selectedUnits.length;

  return (
    <Container>
      {loading ? <div>Loading</div> : <h1>{property?.title}</h1>}
      <p>
        {owner_user?.first_name} {owner_user?.last_name}
      </p>
      <InfoArea className="info">
        <div className="address">
          <p>{address_line_1}</p>
          {address_line_2 && <p>{address_line_2}</p>}
          <p>
            {city}, {state} {zip}
          </p>
        </div>
        <div className="unit-data">
          <p>Units: {totalUnitCount}</p>
          <p>Vacancy Rate: {vacancyRate}</p>
        </div>
      </InfoArea>

      <br />
      <hr />
      <br />
      <HeaderBar>
        <h3>Units</h3>
        {selectedUnitCount > 0 && (
          <div className="table-action-buttons">
            <Button
              variant="contained"
              size="small"
              disabled={selectedUnitCount > 1}
              onClick={editUnit}
            >
              Edit
            </Button>
            <Button
              variant="outlined"
              size="small"
              color="error"
              onClick={deleteUnits}
            >
              Delete
            </Button>
          </div>
        )}
      </HeaderBar>
      <div className="unit-table">
        <TableContainer>
          <DataGrid
            columns={unitCols}
            rows={units || []}
            selectionModel={selectedUnits}
            onSelectionModelChange={selectUnit}
            checkboxSelection
            loading={loading}
          />
        </TableContainer>
        <TableActionArea>
          <Button variant="outlined" onClick={openModal}>
            Add Unit
          </Button>
        </TableActionArea>
      </div>
      <Modal open={modalOpen} onClose={closeModal}>
        <Box sx={boxSx}>
          <AddEditUnit
            closeModal={closeModal}
            property={property}
            unit={unitState}
            refetchUnits={() => {
              fetchProperty(axiosPrivate, id, setProperty, setLoading);
            }}
          />
        </Box>
      </Modal>
    </Container>
  );
}

const boxSx = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 800,
  bgcolor: 'white',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const Container = styled.div`
  width: 100%;
  height: 100%;
`;

const InfoArea = styled.div`
  display: flex;
  div {
    margin-right: 24px;
  }
`;

const HeaderBar = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;

  .table-action-buttons button {
    margin-right: 8px;
  }
`;

const TableContainer = styled.div`
  height: calc(100vh - 400px);
  margin-top: 12px;
`;

const TableActionArea = styled.div`
  display: flex;
`;

export default Property;
