import React from 'react';

import styled from 'styled-components';
import { Button, Box, Modal } from '@mui/material';
import { Close } from '@mui/icons-material';

function FilePreviewModal(props) {
  const { closeModal, modalOpen, doc } = props;

  return (
    <Modal open={modalOpen} onClose={closeModal}>
      <Box sx={modalBoxSx}>
        <div>
          <Top>
            <h3>Document Preview: {doc?.title}</h3>

            <div>
              <a href={doc?.uri} target="_blank" rel="noreferrer noopener">
                <Button variant="contained">Open File in New Tab</Button>
              </a>
              <Button onClick={closeModal}>
                <Close size="large" />
              </Button>
            </div>
          </Top>
          <DocContainer>
            <object data={doc?.uri}>Document failed to load.</object>
          </DocContainer>
        </div>
      </Box>
    </Modal>
  );
}

const modalBoxSx = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '90%',
  bgcolor: 'white',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const Top = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 12px;

  a {
    text-decoration: none;
  }
`;

const DocContainer = styled.div`
  height: 80vh;
  width: 100%;
  object {
    width: 100%;
    height: 100%;
  }
`;

export default FilePreviewModal;
