// Absolute Imports
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

// MUI imports
import Modal from '@mui/material/Modal';
import { Button, Box } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';

// Relative Imports
import AddProperty from './Modals/AddProperty';
import useAxiosPrivate from '../../../hooks/useAxiosPrivate';
import {
  columns,
  PROPERTY_URL,
  transformPropertiesData,
} from './propertiesUtils';

const fetchProperties = async (axiosPrivate, setProperties, setLoading) => {
  try {
    const response = await axiosPrivate.get(PROPERTY_URL);

    setProperties(transformPropertiesData(response.data));
    setLoading(false);
  } catch (error) {
    setLoading(false);
    console.log('error: ', error);
  }
};

function Properties() {
  const axiosPrivate = useAxiosPrivate();
  const navigate = useNavigate();

  const [properties, setProperties] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [loading, setLoading] = useState(true);

  const openModal = () => setModalOpen(true);
  const closeModal = () => setModalOpen(false);

  useEffect(() => {
    fetchProperties(axiosPrivate, setProperties, setLoading);
  }, [axiosPrivate]);

  const handleRowClick = ({ id }) => {
    navigate('/admin/properties/' + id);
  };

  return (
    <Container>
      <h1>Properties</h1>
      <TableContainer>
        <DataGrid
          columns={columns}
          rows={properties}
          onRowClick={handleRowClick}
          loading={loading}
        />
      </TableContainer>
      <ActionsContainer>
        <Button variant="outlined" onClick={openModal}>
          Add Property
        </Button>
      </ActionsContainer>
      <Modal open={modalOpen} onClose={closeModal}>
        <Box sx={boxSx}>
          <AddProperty
            closeModal={closeModal}
            refetchProperties={() =>
              fetchProperties(axiosPrivate, setProperties, setLoading)
            }
          />
        </Box>
      </Modal>
    </Container>
  );
}

const boxSx = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 500,
  bgcolor: 'white',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const Container = styled.div``;

const TableContainer = styled.div`
  height: calc(100vh - 200px);
  margin-top: 12px;
`;

const ActionsContainer = styled.div`
  margin-top: 12px;
`;

export default Properties;
