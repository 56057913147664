// Absolute Imports
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

// MUI imports
import { Button, Autocomplete, TextField } from '@mui/material';

// Relative Imports
import {
  defaultPropertyState,
  PROPERTY_URL,
  validateNewProperty,
} from '../propertiesUtils';
import useAxiosPrivate from '../../../../hooks/useAxiosPrivate';

const getOwnerName = (owner) => {
  return `${owner.first_name} ${owner.last_name}`;
};

function AddProperty(props) {
  const { closeModal, refetchProperties } = props;
  const axiosPrivate = useAxiosPrivate();
  const [owners, setOwners] = useState([]);
  const [propertyState, setPropertyState] = useState(defaultPropertyState);
  const [ownerInput, setOwnerInput] = useState('');
  const [formErrors, setFormErrors] = useState({});

  useEffect(() => {
    const fetchOwners = async () => {
      const ownersResponse = await axiosPrivate.get(
        `/api/admin/users/type/owner`
      );

      setOwners(ownersResponse.data);
    };

    fetchOwners();
  }, [axiosPrivate]);

  const handleFormChange = (e) => {
    e.preventDefault();
    const { value, name } = e.target;
    const error = formErrors[name];
    if (error) {
      setFormErrors({ ...formErrors, [name]: null });
    }

    setPropertyState({ ...propertyState, [name]: value });
  };

  const handleOwnerChange = (e, newValue) => {
    e?.preventDefault();
    const error = formErrors.owner;
    if (error) {
      setFormErrors({ ...formErrors, owner: null });
    }
    setPropertyState({ ...propertyState, owner: newValue });
  };

  const handleOwnerInputChange = (e, newValue) => {
    e?.preventDefault();
    const error = formErrors.owner;
    if (error) {
      setFormErrors({ ...formErrors, owner: null });
    }
    setOwnerInput(newValue);
  };

  const postProperty = async (e) => {
    e.preventDefault();
    const body = validateNewProperty(propertyState);

    if (body.errors) {
      setFormErrors(body.errors);
    } else {
      axiosPrivate.post(PROPERTY_URL, body).then(() => {
        refetchProperties();
        closeModal();
      });
    }
  };

  return (
    <FormContainer onSubmit={postProperty}>
      <h3>Add New Property</h3>

      <Autocomplete
        id="owner-select"
        options={owners}
        value={propertyState.owner}
        onChange={handleOwnerChange}
        inputValue={ownerInput}
        onInputChange={handleOwnerInputChange}
        getOptionLabel={(opt) => getOwnerName(opt)}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Owner"
            name="owner"
            error={!!formErrors.owner}
            helperText={formErrors.owner}
          />
        )}
      />
      <TextField
        label="Property Name"
        name="title"
        value={propertyState.title}
        onChange={handleFormChange}
        error={!!formErrors.title}
        helperText={formErrors.title}
      />
      <TextField
        label="Address Line 1"
        name="address_line_1"
        value={propertyState.address_line_1}
        onChange={handleFormChange}
        error={!!formErrors.address_line_1}
        helperText={formErrors.address_line_1}
      />
      <TextField
        label="Address Line 2"
        name="address_line_2"
        value={propertyState.address_line_2}
        onChange={handleFormChange}
        error={!!formErrors.address_line_2}
        helperText={formErrors.address_line_2}
      />
      <TextField
        label="City"
        name="city"
        value={propertyState.city}
        onChange={handleFormChange}
        error={!!formErrors.city}
        helperText={formErrors.city}
      />
      <TextField
        label="State"
        name="state"
        value={propertyState.state}
        onChange={handleFormChange}
        error={!!formErrors.state}
        helperText={formErrors.state}
      />
      <TextField
        label="Zip"
        name="zip"
        value={propertyState.zip}
        onChange={handleFormChange}
        error={!!formErrors.zip}
        helperText={formErrors.zip}
      />

      <ButtonArea className="button-area">
        <Button variant="contained" type="submit">
          Save
        </Button>
        <Button variant="outlined" onClick={closeModal}>
          Cancel
        </Button>
      </ButtonArea>
    </FormContainer>
  );
}

const FormContainer = styled.form`
  display: grid;
  grid-template-columns: 100%;
  grid-gap: 12px;
`;

const ButtonArea = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: auto auto;
  grid-gap: 8px;
  margin-top: 12px;
`;

export default AddProperty;
