import React from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { Button } from '@mui/material';
import MenuOpenIcon from '@mui/icons-material/MenuOpen';

import useAuth from '../../hooks/useAuth';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';

import logo from '../../imgs/full-logo.png';

function NavBar({ toggleCollapsedSideBar }) {
  const axiosPrivate = useAxiosPrivate();
  const { setAuth } = useAuth();
  const navigate = useNavigate();

  const logOut = async () => {
    setAuth({});
    try {
      localStorage.setItem('jwt', null);
      navigate('/');
      await axiosPrivate.get('/auth/log-out');
    } catch (error) {
      console.log('Logout error: ', error);
    }
  };

  return (
    <Container>
      <LeftWrapper className="left">
        <MenuOpenIcon size="large" onClick={toggleCollapsedSideBar} />

        <Logo src={logo} alt="" />
      </LeftWrapper>
      <RightWrapper>
        <Button variant="text" onClick={logOut}>
          Log Out
        </Button>
      </RightWrapper>
    </Container>
  );
}

const Container = styled.nav`
  width: 100vw;
  height: 50px;
  padding: 0 12px;
  background-color: #333;
  color: white;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const LeftWrapper = styled.div`
  display: flex;
  align-items: center;
  img {
    margin-left: 12px;
  }
`;

const Logo = styled.img`
  height: 45px;
`;

const RightWrapper = styled.div`
  display: flex;
`;

export default NavBar;
