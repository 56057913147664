import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

import Login from './views/Login/Login';

import './App.css';
import Admin from './views/Admin/Admin';
import Tenant from './views/Tenant/Tenant';
import Owner from './views/Owner/Owner';
import ForgotPassword from './views/Login/ForgotPassword';
import NewTenantApplication from './views/Tenant/NewTenantApplication';

function App() {
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Router>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="forgot-password" element={<ForgotPassword />} />
          <Route path="admin/*" element={<Admin />} />
          <Route path="tenant/*" element={<Tenant />} />
          <Route path="owner" element={<Owner />} />
          <Route path="new-application" element={<NewTenantApplication />} />
        </Routes>
      </Router>
    </LocalizationProvider>
  );
}

export default App;
