import { formatToPercent } from '../../../utils';

export const PROPERTY_URL = '/api/admin/properties';

export const columns = [
  {
    headerName: 'Property',
    field: 'title',
    sortable: true,
    width: 200,
  },
  {
    headerName: 'Owner',
    field: 'owner_name',
    sortable: true,
    width: 200,
  },
  {
    headerName: 'Address',
    field: 'address_line_1',
    width: 200,
  },
  {
    headerName: 'City',
    field: 'city',
  },
  {
    headerName: '# of Units',
    field: 'totalUnitCount',
  },
  {
    headerName: 'Vacant Units',
    field: 'vacancyCount',
  },

  // active
  // repair requests
  // todo items?
];

export const transformPropertiesData = (properties) => {
  return properties.map((property) => transformPropertyData(property));
};

const transformUnitsData = (units) => {
  return units.map((unit) => {
    unit.status = !unit.tenant_account ? 'VACANT' : '';
    return unit;
  });
};

export const transformPropertyData = (property) => {
  const owner = property.owner_data;
  const totalUnitCount = property.units.length;
  const vacancyCount = property.units.reduce((data, unit) => {
    return !unit.tenant_account ? data + 1 : data;
  }, 0);

  const vacancyRate = totalUnitCount ? vacancyCount / totalUnitCount : 0;
  const owner_name = owner ? `${owner.first_name} ${owner.last_name}` : null;

  return {
    ...property,
    owner_name,
    totalUnitCount,
    vacancyCount,
    vacancyRate: formatToPercent(vacancyRate) || '--',
    units: transformUnitsData(property.units),
  };
};

export const unitCols = [
  {
    headerName: 'Unit',
    field: 'title',
  },
  {
    headerName: 'Status',
    field: 'status',
  },
];

export const defaultPropertyState = {
  owner: null,
  title: '',
  address_line_1: '',
  address_line_2: '',
  city: '',
  state: '',
  zip: '',
};

export const validateNewProperty = (property) => {
  const errors = {};
  let foundError = false;
  const newProperty = { ...property };

  if (!property.owner) {
    errors.owner = 'Owner is required';
    foundError = true;
  } else {
    newProperty.owner_user = property?.owner?.id;
    delete newProperty.owner;
  }
  if (!property.title) {
    errors.title = 'Title is required';
    foundError = true;
  }

  if (foundError) {
    newProperty.errors = errors;
  }

  return newProperty;
};
