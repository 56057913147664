import React, { useEffect, useState } from 'react';
import { Card, CardHeader, CardContent } from '@mui/material';
import styled from 'styled-components';
import useAxiosPrivate from '../../../hooks/useAxiosPrivate';

function Dashboard() {
  const [applicationData, setApplicationData] = useState({});
  const axiosPrivate = useAxiosPrivate();

  useEffect(() => {
    const fetchData = async () => {
      const res = await axiosPrivate.get('/api/owner/dashboard');

      console.log('res: ', res.data);
      setApplicationData(res.data);
    };

    fetchData();
  }, [axiosPrivate]);

  return (
    <Container>
      <SectionHeader>Applications</SectionHeader>
      <SectionGrid>
        <CustomCard>
          <CardHeader title="New"></CardHeader>
          <CardContent sx={cardContentSx}>
            {applicationData.unread || 0}
          </CardContent>
        </CustomCard>
        <CustomCard>
          <CardHeader title="Background Check Complete"></CardHeader>
          <CardContent sx={cardContentSx}>
            {applicationData.background_check_complete || 0}
          </CardContent>
        </CustomCard>
      </SectionGrid>
      <SectionHeader>Action Needed</SectionHeader>
      <SectionGrid>
        <CustomCard>
          <CardHeader title="Items"></CardHeader>
          <CardContent sx={cardContentSx}>0</CardContent>
        </CustomCard>
      </SectionGrid>
    </Container>
  );
}

const CustomCard = ({ children, ...rest }) => {
  return (
    <Card sx={cardSx} {...rest}>
      {children}
    </Card>
  );
};

const cardSx = {
  textAlign: 'center',
  backgroundColor: '#eee',
};
const cardContentSx = {
  fontSize: '32px',
};

const Container = styled.div``;

const SectionHeader = styled.h2`
  margin: 12px;
`;

const SectionGrid = styled.div`
  display: grid;
  grid-gap: 12px;
  grid-template-columns: repeat(4, 1fr);
`;

export default Dashboard;
