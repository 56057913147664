import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import Modal from '@mui/material/Modal';
import { Button } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { Box } from '@mui/system';

import useAxiosPrivate from '../../../hooks/useAxiosPrivate';
import { columns, USERS_URL } from './usersUtils';
import AddEditUser from './Modals/AddEditUser';

const fetchUsersData = async (axiosPrivate, setUsers, setLoading) => {
  try {
    const response = await axiosPrivate.get(USERS_URL);
    setUsers(response.data);
    setLoading(false);
  } catch (error) {
    setLoading(false);
    console.log('error: ', error);
  }
};

function Users() {
  const axiosPrivate = useAxiosPrivate();
  const [users, setUsers] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [userState, setUserState] = useState(null);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [loading, setLoading] = useState(true);

  const openModal = () => setModalOpen(true);
  const closeModal = () => setModalOpen(false);

  useEffect(() => {
    fetchUsersData(axiosPrivate, setUsers, setLoading);
  }, [axiosPrivate]);

  const selectUser = (selection) => {
    setSelectedUsers(selection);
  };

  const editUser = () => {
    const [selected] = selectedUsers;
    console.log('selectedUsers: ', selectedUsers);
    const user = users.find((user) => user.id === selected);
    setUserState(user);
    setModalOpen(true);
  };

  const deleteUsers = async () => {
    console.log(selectedUsers);
  };

  const selectedUserCount = selectedUsers.length;

  return (
    <Container>
      <HeaderBar>
        {selectedUserCount > 0 && (
          <div className="table-action-buttons">
            <Button
              variant="contained"
              size="small"
              disabled={selectedUserCount > 1}
              onClick={editUser}
            >
              Edit
            </Button>
            <Button
              variant="outlined"
              size="small"
              color="error"
              onClick={deleteUsers}
            >
              Delete
            </Button>
          </div>
        )}
      </HeaderBar>

      <TableContainer>
        <DataGrid
          columns={columns}
          rows={users}
          selectionModel={selectedUsers}
          onSelectionModelChange={selectUser}
          checkboxSelection
          loading={loading}
        />
      </TableContainer>
      <ActionsContainer>
        <Button variant="outlined" onClick={openModal}>
          Add User
        </Button>
      </ActionsContainer>
      <Modal open={modalOpen} onClose={closeModal}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 600,
            bgcolor: 'white',
            border: '2px solid #000',
            boxShadow: 24,
            p: 4,
          }}
        >
          <AddEditUser
            user={userState}
            closeModal={closeModal}
            refetchUsers={() =>
              fetchUsersData(axiosPrivate, setUsers, setLoading)
            }
          />
        </Box>
      </Modal>
    </Container>
  );
}

const Container = styled.div``;
const HeaderBar = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;

  h1 {
    margin-right: 12px;
  }

  .table-action-buttons button {
    margin-right: 8px;
  }
`;

const TableContainer = styled.div`
  height: calc(100vh - 200px);
  margin-top: 12px;
`;

const ActionsContainer = styled.div`
  margin-top: 12px;
`;

export default Users;
