export const PROPERTY_URL = '/api/admin/applications';

export const columns = [
  {
    headerName: 'Application',
    field: 'name',
    sortable: true,
    width: 200,
  },
  {
    headerName: 'Status',
    field: 'application_status',
    sortable: true,
    width: 100,
  },
  {
    headerName: 'Phone',
    field: 'phone',
    width: 150,
  },
  {
    headerName: 'Employer',
    field: 'current_employer',
    width: 150,
  },
];

export const transformApplicationData = (applications) => {
  return applications.map((property) => {
    return {
      ...property,
      name: `${property.first_name} ${property.last_name}`,
    };
  });
};

export const defaultApplicationState = {};

export const applicationsFormFields = [
  {
    key: 'title',
    label: 'Unit',
  },
  { key: 'address_line_1' },
  { key: 'address_line_2' },
  { key: 'city' },
  { key: 'state' },
  { key: 'zip' },
];
