import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import styled from 'styled-components';
import { Box, Button, Modal } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';

import useAxiosPrivate from '../../../hooks/useAxiosPrivate';
import { modalBoxSx } from '../../../styles/utils';
import UploadDocument from './Modals/UploadAccountDocument';
import FilePreviewModal from './Modals/FilePreviewModal';

const fetchAccount = async (
  axiosPrivate,
  id,
  setAccount,
  setAccountDocs,
  setLoading
) => {
  const accountRes = await axiosPrivate.get(`/api/admin/accounts/${id}`);
  setAccount(accountRes.data);
  const accountDocsRes = await axiosPrivate.get(
    `/api/admin/accounts/${id}/documents`
  );
  setAccountDocs(accountDocsRes.data);
  setLoading(false);
};

function Account() {
  const axiosPrivate = useAxiosPrivate();
  const { id } = useParams();

  const [loading, setLoading] = useState(true);
  const [modalOpen, setModalOpen] = useState(false);
  const [file, setFile] = useState(null);
  const openModal = () => setModalOpen(true);
  const closeModal = () => setModalOpen(false);

  const [account, setAccount] = useState();
  const [accountDocs, setAccountDocs] = useState([]);

  useEffect(() => {
    fetchAccount(axiosPrivate, id, setAccount, setAccountDocs, setLoading);
  }, [axiosPrivate, id]);

  const handleCellClick = ({ field, row }) => {
    if (field === 'uri') {
      setFile(row);
    }
  };

  return (
    <Container>
      {loading ? (
        <h1>Loading Account</h1>
      ) : (
        <div>
          <h1>{account.title}</h1>
          <p>(Tenant Account)</p>
        </div>
      )}
      <br />
      <h3>Account Documents</h3>
      <DataContainer>
        <DataGrid
          columns={[
            { headerName: 'Document', field: 'title', width: 150 },
            { headerName: 'Link', field: 'uri', width: 300 },
            { headerName: 'Tags', field: 'tags', width: 300 },
          ]}
          rows={accountDocs}
          // checkboxSelection
          onCellClick={handleCellClick}
        />
      </DataContainer>
      <ActionsContainer>
        <Button variant="contained" onClick={openModal}>
          Upload Document
        </Button>
      </ActionsContainer>
      <Modal open={modalOpen} onClose={closeModal}>
        <Box sx={modalBoxSx}>
          <UploadDocument
            closeModal={closeModal}
            id={id}
            refetchData={() =>
              fetchAccount(
                axiosPrivate,
                id,
                setAccount,
                setAccountDocs,
                setLoading
              )
            }
          />
        </Box>
      </Modal>
      <FilePreviewModal
        doc={file}
        modalOpen={!!file}
        closeModal={() => setFile(null)}
      />
    </Container>
  );
}

const Container = styled.section``;

const DataContainer = styled.div`
  margin-top: 4px;
  height: 500px;
`;

const ActionsContainer = styled.div`
  margin-top: 12px;
`;

export default Account;
