//  'unit_id'}
export const defaultFormState = {
  first_name: '',
  last_name: '',
  phone: '',
  email: '',
  birth_date: Date.now() - 1000 * 3600 * 24 * 365.25 * 18,
  ssn: '',
  emergency_contact_name: '',
  emergency_contact_relationship: '',
  emergency_contact_phone: '',
  emergency_contact_address: '',
  reference_1_name: '',
  reference_1_phone: '',
  previous_address: '',
  drivers_license_number: '',
  drivers_license_state: '',
  other_occupants: '',
  automobile_make: '',
  auto_model: '',
  auto_year: '',
  application_notes: '',
  has_pets: false,
  smoker: false,
  previous_landlord_name: '',
  previous_landlord_phone: '',
  reference_2_name: '',
  reference_2_phone: '',
  current_employer: '',
  current_employer_phone: '',
  current_employer_address: '',
  current_employer_position: '',
  notes: '',
  agree: false,
};

// export const defaultFormState = {
//   first_name: 'First',
//   last_name: 'Name',
//   phone: '801-555-5555',
//   email: 'example@test.com',
//   birth_date: Date.now() - 1000 * 3600 * 24 * 365.75 * 33,
//   emergency_contact_name: 'Emergency Contact',
//   emergency_contact_relationship: 'Emergency Contact Friend',
//   emergency_contact_phone: '801-555-0000',
//   emergency_contact_address: '123 Address',
//   reference_1_name: 'Ref Name',
//   reference_1_phone: '801-555-1111',
//   previous_address: 'Home - basement',
//   drivers_license_number: '',
//   drivers_license_state: '',
//   other_occupants: '',
//   automobile_make: '',
//   auto_model: '',
//   auto_year: '',
//   application_notes: '',
//   has_pets: false,
//   smoker: false,
//   previous_landlord_name: '',
//   previous_landlord_phone: '',
//   reference_2_name: '',
//   reference_2_phone: '',
//   current_employer: '',
//   current_employer_phone: '',
//   current_employer_address: '',
//   current_employer_position: '',
// };

export const getInitialLocalState = () => {
  const savedData = JSON.parse(localStorage.getItem('formData'));
  const now = Date.now();

  if (savedData && now < savedData.expiration) {
    const initialState = {};
    for (const key in defaultFormState) {
      const savedElement = savedData[key];
      if (savedElement) {
        initialState[key] = savedElement;
      } else {
        initialState[key] = defaultFormState[key];
      }
    }

    return initialState;
  }
  return defaultFormState;
};

export const saveFormStateToLocalStorage = (formState) => {
  const state = { ...formState };
  state.expiration = Date.now() + 24 + 60 * 60 * 1000;

  delete state.ssn;
  delete state.idImg;

  localStorage.setItem('formData', JSON.stringify(state));
};

export const applicationSectionData = [
  {
    title: 'Personal Info',
    requiredFields: [
      'first_name',
      'last_name',
      'birth_date',
      'email',
      'phone',
      'ssn',
      'drivers_license_number',
      'drivers_license_state',
      'idImg',
    ],
  },
  {
    title: 'Additional Info',
    requiredFields: [
      'emergency_contact_name',
      'emergency_contact_relationship',
      'emergency_contact_phone',
    ],
  },
  {
    title: 'References',
    requiredFields: [
      'previous_landlord_name',
      'previous_landlord_phone',
      'reference_1_name',
      'reference_1_phone',
      'current_employer',
      'current_employer_phone',
    ],
  },
  { title: 'Payment', requiredFields: ['payment'] },
  { title: 'Finish', requiredFields: [] },
];

export const validateApplication = (application, requiredFields) => {
  console.log('requiredFields: ', requiredFields);
  const newApplication = { ...application };
  const errors = {};
  let foundErrors = false;

  for (let i = 0; i < requiredFields.length; i++) {
    const field = requiredFields[i];

    if (!newApplication[field]) {
      foundErrors = true;
      errors[field] = 'Required';
    }
  }

  const { ssn } = newApplication;
  if (ssn) {
    const cleanSsn = Number(ssn.replace(/[^\d]/g, ''));

    if (cleanSsn < 100000000 || cleanSsn > 999999999) {
      foundErrors = true;
      errors.ssn = 'Please enter a valid SSN';
    }
  }

  if (foundErrors) {
    newApplication.errors = errors;
  }

  return newApplication;
};
