import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import { Autocomplete, Button, TextField } from '@mui/material';

import useAxiosPrivate from '../../../../hooks/useAxiosPrivate';

const handleError = (error) => console.log('error: ', error);

const tenantOptionDisplay = (t) =>
  `${t.first_name} ${t.last_name} (${t.email || t.phone_number})`;

const findPropertyAndUnit = (properties, account) => {
  if (!account) return {};

  let property = null;
  let unit = null;

  for (let i = 0; i < properties.length; i++) {
    const prop = properties[i];
    const matchingUnit = prop.units.find((u) => u.id === account.unit_id);

    if (matchingUnit) {
      property = prop.title;
      unit = matchingUnit.title;

      break;
    }
  }

  return { property, unit };
};

function AddEditAccount(props) {
  const axiosPrivate = useAxiosPrivate();
  const { account, closeModal, refetchAccounts } = props;

  const editMode = !!account;

  const [properties, setProperties] = useState([]);
  const [selectedProperty, setSelectedProperty] = useState(null);
  const [units, setUnits] = useState([]);
  const [selectedUnit, setSelectedUnit] = useState(null);
  const [tenantUsers, setTenantUsers] = useState([]);
  const [selectedTenant, setSelectedTenant] = useState(null);
  const [accountName, setAccountName] = useState(account?.title ?? '');

  useEffect(() => {
    const fetchData = async () => {
      try {
        axiosPrivate
          .get('/api/admin/properties')
          .then(({ data }) => {
            setProperties(data);
            const { property, unit } = findPropertyAndUnit(data, account);
            setSelectedProperty(property);
            setSelectedUnit(unit);
          })
          .catch(handleError);
        axiosPrivate
          .get('/api/admin/users/type/tenant')
          .then(({ data }) => {
            setTenantUsers(data);
          })
          .catch(handleError);
      } catch (error) {
        console.log('Caught error: ', error);
      }
    };

    fetchData();
  }, [account, axiosPrivate]);

  useEffect(() => {
    const selectedPropertyUnits = properties.find(
      (p) => p.title === selectedProperty
    )?.units;

    setUnits(selectedPropertyUnits || []);
  }, [properties, selectedProperty]);

  const handlePropertyChange = (e, value) => {
    setSelectedProperty(value);
  };
  const handleUnitChange = (e, value) => {
    setSelectedUnit(value);
  };
  const handleTenantChange = (e, value) => {
    setSelectedTenant(value);
    if (value) {
      setAccountName(value);
    }
  };

  const handleSubmit = async () => {
    const unit_id = units.find((u) => u.title === selectedUnit)?.id;
    const tenant_id = tenantUsers.find(
      (t) => tenantOptionDisplay(t) === selectedTenant
    )?.id;

    try {
      const method = editMode ? axiosPrivate.put : axiosPrivate.post;
      const url = editMode
        ? `/api/admin/accounts/${account.id}`
        : '/api/admin/accounts';

      await method(url, {
        unit_id,
        tenant_id,
        title: accountName,
      });

      refetchAccounts();
      closeModal();
    } catch (error) {
      console.log('Add account error: ', error);
    }
  };

  const propertyOptions = properties.map((property) => property.title);
  const unitOptions = units.map((u) => u.title);
  const tenantUserOptions = tenantUsers.map(tenantOptionDisplay);

  return (
    <form>
      <FormContainer>
        <h3>{editMode ? 'Edit' : 'Add'} Tenant Account</h3>
        <Autocomplete
          id="property-select"
          options={propertyOptions}
          value={selectedProperty}
          onChange={handlePropertyChange}
          renderInput={(params) => (
            <TextField
              autoFocus
              required
              {...params}
              label="Select Property"
              name="property"
              // error={!!formErrors.property}
              // helperText={formErrors.tenant}
            />
          )}
        />
        <Autocomplete
          id="unit-select"
          options={unitOptions}
          value={selectedUnit}
          onChange={handleUnitChange}
          disabled={!selectedProperty}
          renderInput={(params) => (
            <TextField
              required
              {...params}
              label="Unit"
              name="unit"
              // error={!!formErrors.unit}
              // helperText={formErrors.tenant}
            />
          )}
        />
        <Autocomplete
          id="tenant-select"
          options={tenantUserOptions}
          value={selectedTenant}
          onChange={handleTenantChange}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Tenant User Account (optional)"
              name="tenant"
              // error={!!formErrors.tenant}
              // helperText={formErrors.tenant}
            />
          )}
        />
        <TextField
          label="Account Name"
          value={accountName}
          onChange={(e) => setAccountName(e.target.value)}
        />
      </FormContainer>
      <ButtonArea>
        <Button variant="contained" onClick={handleSubmit}>
          Save
        </Button>
        <Button variant="outlined" onClick={closeModal}>
          Cancel
        </Button>
      </ButtonArea>
    </form>
  );
}

const FormContainer = styled.div`
  display: grid;
  grid-gap: 12px;
`;

const ButtonArea = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: auto auto;
  grid-gap: 8px;
  margin-top: 12px;
`;

export default AddEditAccount;
