import { Alert, AlertTitle, Button, TextField } from '@mui/material';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import styled from 'styled-components';

import { LoginContainer, LoginForm, LoginHeader } from './Login';

function ForgotPassword() {
  const [email, setEmail] = useState('');
  const [errorText, setErrorText] = useState();
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (email) {
      try {
        axios.post('/auth/forgot-password', { email });

        navigate('/');
      } catch (error) {
        setErrorText(
          'Problem requesting password reset. Please try again or contact us.'
        );
      }
    } else {
      setErrorText('Please input a valid email.');
    }
  };

  return (
    <LoginContainer>
      <LoginBox>
        <LoginHeader>Northbound Properties</LoginHeader>
        <SubHeader>Reset Password</SubHeader>
        <LoginForm onSubmit={handleSubmit}>
          <TextField
            id="reset-email"
            label="Email Address"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <Button type="submit" variant="contained">
            Request Reset Password Email
          </Button>
        </LoginForm>
        {errorText && (
          <Alert severity="error" onClose={() => setErrorText('')}>
            <AlertTitle>Error</AlertTitle>
            {errorText}
          </Alert>
        )}
      </LoginBox>
    </LoginContainer>
  );
}

const LoginBox = styled.div`
  padding: 36px;
  border-radius: 8px;
  box-shadow: 2px 2px 5px 1px rgba(0, 0, 0, 0.7);
`;

const SubHeader = styled.h2`
  margin-top: 12px;
  text-align: center;
`;

export default ForgotPassword;
