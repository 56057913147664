export const USERS_URL = '/api/admin/users';

export const columns = [
  {
    headerName: 'First Name',
    field: 'first_name',
    sortable: true,
  },
  {
    headerName: 'Last Name',
    field: 'last_name',
    sortable: true,
  },
  {
    headerName: 'Email',
    field: 'email',
    sortable: true,
    width: 200,
  },
  {
    headerName: 'Type',
    field: 'user_type',
    sortable: true,
  },
];

export const defaultUserState = {
  user_type: 'tenant',
  admin_permissions: false,
  first_name: '',
  last_name: '',
  email: '',
  phone_number: '',
};

export const userFormFields = [
  {
    type: 'select',
    options: ['tenant', 'owner', 'internal'],
    key: 'user_type',
    label: 'User Type',
  },
  {
    type: 'switch',
    key: 'admin_permissions',
    label: 'Is Admin',
    shouldRender: (state) => state.user_type === 'internal',
  },
  {
    key: 'first_name',
  },
  {
    key: 'last_name',
  },
  {
    key: 'email',
  },
  {
    key: 'phone_number',
  },
];
