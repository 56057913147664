import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import Modal from '@mui/material/Modal';
import { Autocomplete, Button, TextField } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { Box } from '@mui/system';

import useAxiosPrivate from '../../../hooks/useAxiosPrivate';
import {
  columns,
  defaultApplicationState,
  applicationsFormFields,
  PROPERTY_URL,
  transformApplicationData,
} from './applicationsUtils';
import { formFieldBuilder } from '../utils';

function Applications() {
  const axiosPrivate = useAxiosPrivate();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [applications, setApplications] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [applicationState, setApplicationState] = useState(
    defaultApplicationState
  );

  const openModal = () => setModalOpen(true);
  const closeModal = () => setModalOpen(false);

  const handleFormChange = (e) => {
    e.preventDefault();
    const { value, name } = e.target;

    setApplicationState({ ...applicationState, [name]: value });
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axiosPrivate.get(PROPERTY_URL);
        setApplications(transformApplicationData(response.data));
        setLoading(false);
      } catch (error) {
        console.log('error: ', error);
        setLoading(false);
      }
    };

    fetchData();
  }, [axiosPrivate]);

  const postApplication = async (e) => {
    e.preventDefault();
    // TODO: validate inputs
    console.log('applicationState: ', applicationState);
    const response = await axiosPrivate.post(PROPERTY_URL, applicationState);
    console.log(response);
  };

  const handleRowClick = ({ id }) => {
    navigate('/admin/applications/' + id);
  };

  return (
    <Container>
      <h1>Applications</h1>

      <TableContainer>
        <DataGrid
          columns={columns}
          rows={applications}
          onRowClick={handleRowClick}
          loading={loading}
        />
      </TableContainer>
      <ActionsContainer>
        <Button variant="outlined" onClick={openModal}>
          Add Application
        </Button>
      </ActionsContainer>
      <Modal open={modalOpen} onClose={closeModal}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 500,
            bgcolor: 'white',
            border: '2px solid #000',
            boxShadow: 24,
            p: 4,
          }}
        >
          <FormContainer onSubmit={postApplication}>
            <h3>Add New Application</h3>

            <Autocomplete
              disablePortal
              id="combo-box-demo"
              options={['Stillwater', 'West Brook Cabins']}
              renderInput={(params) => <TextField {...params} label="Owner" />}
            />
            {formFieldBuilder(
              applicationsFormFields,
              handleFormChange,
              applicationState
            )}
            <div className="button-area">
              <Button variant="contained" type="submit">
                Save
              </Button>
              <Button variant="outlined" onClick={closeModal}>
                Cancel
              </Button>
            </div>
          </FormContainer>
        </Box>
      </Modal>
    </Container>
  );
}

const Container = styled.div``;
const FormContainer = styled.form`
  display: grid;
  grid-template-columns: 100%;
  grid-gap: 12px;

  .button-area {
    width: 100%;
    display: grid;
    grid-template-columns: 50% 50%;
    grid-gap: 8px;
  }
`;

const TableContainer = styled.div`
  height: calc(100vh - 200px);
  margin-top: 12px;
`;

const ActionsContainer = styled.div`
  margin-top: 12px;
`;

export default Applications;
