// External Imports
import React, { useCallback, useState } from 'react';
import styled from 'styled-components';
import Dropzone from 'react-dropzone';

// Internal Imports
import { Button } from '@mui/material';
import { errorRed } from '../styles/colors';
import { InsertDriveFile, PictureAsPdf, VideoFile } from '@mui/icons-material';

const getFileIcon = (type) => {
  switch (type) {
    case 'application/pdf':
      return PictureAsPdf;
    case 'video/mp4':
      return VideoFile;
    default:
      return InsertDriveFile;
  }
};

const FileUpload = ({ prompt, onFileChange, fileProp, error }) => {
  const [file, setFile] = useState(null);

  const onDrop = useCallback(
    (filesInput) => {
      const fileInput = filesInput[0];
      if (fileInput) {
        const fileData = {};
        fileData.filetype = fileInput.type;
        fileData.name = fileInput.name;

        const reader = new FileReader();
        reader.onabort = () => console.log('file reading was aborted');
        reader.onerror = () => console.log('file reading has failed');
        reader.onload = (rawFile) => {
          const result = rawFile.target.result;
          fileData.data = result;

          setFile(fileData);
          if (onFileChange) {
            onFileChange(fileData);
          }
        };
        // reader.readAsArrayBuffer(file);
        reader.readAsDataURL(fileInput);
      }
    },
    [onFileChange]
  );

  const removeImg = async () => {
    setFile(null);
    onFileChange(null);
  };

  const fileData = file || fileProp;
  const isImage = fileData?.filetype?.includes('image') ?? false;

  const PreviewIcon = getFileIcon(file?.filetype);

  if (fileData) {
    return (
      <PreviewContainer>
        {isImage ? (
          <ImgPreview src={fileData.data} />
        ) : (
          <FilePreview>
            <PreviewIcon size="large" />
            <p>{fileData.name}</p>
          </FilePreview>
        )}
        <Button
          onClick={removeImg}
          variant="outlined"
          sx={{ width: '100%' }}
          color="error"
        >
          Remove
        </Button>
      </PreviewContainer>
    );
  }

  return (
    <Dropzone
      onDrop={onDrop}
      multiple={false}
      style={{ width: 'auto', height: 'auto' }}
    >
      {({ getRootProps, getInputProps }) => (
        <DropArea error={error}>
          <DropContainer {...getRootProps()}>
            <input {...getInputProps()} />
            <p>{prompt}</p>
          </DropContainer>
        </DropArea>
      )}
    </Dropzone>
  );
};

const DropArea = styled.section`
  width: 100%;
  height: 100%;
  border: 2px solid ${({ error }) => (error ? errorRed : '#bbb')};
  border-radius: 4px;
  color: ${({ error }) => (error ? errorRed : 'black')};
`;

const DropContainer = styled.div`
  width: 100%;
  height: 100%;
  padding: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  cursor: pointer;
`;

const PreviewContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  max-width: 450px;
`;

const ImgPreview = styled.img`
  max-width: 100%;
  max-height: calc(100% - 37px);
  margin-bottom: 6px;
`;

const FilePreview = styled.div`
  height: 100%;
  width: 100%;

  border: 2px solid ${({ error }) => (error ? errorRed : '#bbb')};
  border-radius: 4px;
  max-width: 100%;
  max-height: calc(100% - 37px);
  margin-bottom: 6px;
  padding: 16px;
  display: flex;
  align-items: center;
  justify-content: center;

  p {
    margin-left: 8px;
  }
`;

export default FileUpload;
