export const USERS_URL = '/api/admin/users';

export const defaultUser = {
  user_type: 'owner',
};

export const validateUser = (user) => {
  const newUser = { ...user };
  const errors = {};
  let foundErrors = false;

  if (!user.user_type) {
    foundErrors = true;
    errors.user_type = 'Required';
  }
  if (!user.first_name) {
    foundErrors = true;
    errors.first_name = 'Required';
  }
  if (!user.last_name) {
    foundErrors = true;
    errors.last_name = 'Required';
  }

  if (foundErrors) {
    newUser.errors = errors;
  }

  return newUser;
};
