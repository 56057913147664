import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import { DataGrid } from '@mui/x-data-grid';
import { Box, Button, Modal } from '@mui/material';

import useAxiosPrivate from '../../../hooks/useAxiosPrivate';
import AddEditAccount from './Modals/AddEditAccount';
import { modalBoxSx } from '../../../styles/utils';
import { useNavigate } from 'react-router-dom';

const columns = [
  {
    headerName: 'Account Name',
    field: 'title',
    sortable: true,
    width: 200,
  },
  {
    headerName: 'Unit',
    field: 'unit_name',
    width: 200,
  },
  {
    headerName: 'Tenant has Account',
    field: 'tenant_account',
    width: 150,
  },
];

const transformAccountData = (accounts) => {
  return accounts.map((acc) => {
    const account = { ...acc };

    account.unit_name = `${account.unit.property.title} ${account.unit.title}`;
    account.tenant_account = !!account.tenant_user ? '✔️' : '❌';

    return account;
  });
};

function Accounts() {
  const axiosPrivate = useAxiosPrivate();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(true);
  const [modalOpen, setModalOpen] = useState(false);
  const [accounts, setAccounts] = useState([]);
  const [selectedAccounts, setSelectedAccounts] = useState([]);
  const [accountState, setAccountState] = useState();

  const openModal = () => setModalOpen(true);
  const closeModal = () => setModalOpen(false);

  const fetchAccounts = useCallback(async () => {
    try {
      const { data } = await axiosPrivate.get('/api/admin/accounts');
      setLoading(false);
      setAccounts(transformAccountData(data));
      return data;
    } catch (error) {
      console.log('Caught error: ', error);
    }
  }, [axiosPrivate, setLoading, setAccounts]);

  useEffect(() => {
    fetchAccounts();
  }, [fetchAccounts]);

  const selectAccount = (selection) => {
    setSelectedAccounts(selection);
  };

  const editAccount = () => {
    //TODO:
    const [selected] = selectedAccounts;

    const account = accounts.find((account) => account.id === selected);
    setAccountState(account);
    setModalOpen(true);
  };

  const closeAccounts = () => {
    //TODO:
  };

  const handleRowClick = ({ id }) => {
    navigate('/admin/accounts/' + id);
  };

  const selectedAccountsCount = selectedAccounts.length;

  return (
    <View>
      <HeaderBar>
        <h1>Accounts</h1>
        {selectedAccountsCount > 0 && (
          <div className="table-action-buttons">
            <Button
              variant="contained"
              size="small"
              disabled={selectedAccountsCount > 1}
              onClick={editAccount}
            >
              Edit
            </Button>
            <Button
              variant="outlined"
              size="small"
              color="error"
              onClick={closeAccounts}
            >
              Close
            </Button>
          </div>
        )}
      </HeaderBar>
      <DataContainer>
        <DataGrid
          columns={columns}
          rows={accounts}
          onRowClick={handleRowClick}
          selectionModel={selectedAccounts}
          onSelectionModelChange={selectAccount}
          checkboxSelection
          loading={loading}
        />
      </DataContainer>
      <ActionsContainer>
        <Button variant="outlined" onClick={openModal}>
          Add Account
        </Button>
      </ActionsContainer>
      <Modal open={modalOpen} onClose={closeModal}>
        <Box sx={modalBoxSx}>
          <AddEditAccount
            account={accountState}
            closeModal={closeModal}
            refetchAccounts={fetchAccounts}
          />
        </Box>
      </Modal>
    </View>
  );
}

const View = styled.div``;

const HeaderBar = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;

  h1 {
    margin-right: 12px;
  }

  .table-action-buttons button {
    margin-right: 8px;
  }
`;

const DataContainer = styled.div`
  height: 500px;
`;

const ActionsContainer = styled.div`
  margin-top: 12px;
`;

export default Accounts;
