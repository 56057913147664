// Absolute Imports
import React, { useState } from 'react';
import styled from 'styled-components';

// MUI imports
import { Button, TextField } from '@mui/material';

// Relative Imports
import { initialUnitState, UNIT_URL, validateNewUnit } from './unitUtils';
import useAxiosPrivate from '../../../../hooks/useAxiosPrivate';

function AddEditUnit(props) {
  const { closeModal, property, refetchUnits, unit } = props;
  const axiosPrivate = useAxiosPrivate();
  const [unitState, setUnitState] = useState(initialUnitState(property, unit));
  const [formErrors, setFormErrors] = useState({});

  const editMode = !!unit;

  const handleFormChange = (e) => {
    e.preventDefault();
    const { value, name } = e.target;
    const error = formErrors[name];
    if (error) {
      setFormErrors({ ...formErrors, [name]: null });
    }

    setUnitState({ ...unitState, [name]: value });
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    const body = validateNewUnit({
      ...unitState,
      property_id: property.id,
    });

    if (body.errors) {
      setFormErrors(body.errors);
    } else {
      const method = editMode ? axiosPrivate.put : axiosPrivate.post;
      const url = editMode ? `${UNIT_URL}/${unit.id}` : UNIT_URL;

      try {
        await method(url, body);

        refetchUnits();
        closeModal();
      } catch (error) {
        console.log('error: ', error);
      }
    }
  };

  return (
    <Container>
      <h2>{editMode ? 'Edit' : 'Add'} Unit</h2>
      <br />
      <form onSubmit={onSubmit}>
        <FormLayout>
          <TextField
            autoFocus
            label="Unit"
            placeholder="Unit Name/Number"
            name="title"
            value={unitState.title || ''}
            onChange={handleFormChange}
            error={!!formErrors.title}
            helperText={formErrors.title}
            sx={doubleColSx}
          />
          <TextField
            label="Description"
            name="description"
            value={unitState.description || ''}
            onChange={handleFormChange}
            error={!!formErrors.description}
            helperText={formErrors.description}
            multiline
            minRows={7}
            sx={descColSx}
          />
          <TextField
            label="Address Line 1"
            name="address_line_1"
            value={unitState.address_line_1 || ''}
            onChange={handleFormChange}
            error={!!formErrors.address_line_1}
            helperText={formErrors.address_line_1}
            sx={doubleColSx}
          />
          <TextField
            label="Address Line 2"
            name="address_line_2"
            value={unitState.address_line_2 || ''}
            onChange={handleFormChange}
            error={!!formErrors.address_line_2}
            helperText={formErrors.address_line_2}
            sx={doubleColSx}
          />
          <TextField
            label="City"
            name="city"
            value={unitState.city || ''}
            onChange={handleFormChange}
            error={!!formErrors.city}
            helperText={formErrors.city}
            sx={doubleColSx}
          />
          <TextField
            label="State"
            name="state"
            value={unitState.state || ''}
            onChange={handleFormChange}
            error={!!formErrors.state}
            helperText={formErrors.state}
          />
          <TextField
            label="Zip"
            name="zip"
            value={unitState.zip || ''}
            onChange={handleFormChange}
            error={!!formErrors.zip}
            helperText={formErrors.zip}
          />
        </FormLayout>
        <ButtonArea className="button-area">
          <Button variant="contained" type="submit">
            Save
          </Button>
          <Button variant="outlined" onClick={closeModal}>
            Cancel
          </Button>
        </ButtonArea>
      </form>
    </Container>
  );
}

const doubleColSx = {
  gridColumnStart: 1,
  gridColumnEnd: 'span 2',
};

const descColSx = {
  gridColumnStart: 3,
  gridColumnEnd: 'span 2',
  gridRowStart: 1,
  gridRowEnd: 'span 3',
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const FormLayout = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 12px;
`;

const ButtonArea = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: auto auto;
  grid-gap: 8px;
  grid-column-start: 1;
  grid-column-end: end;
  margin-top: 12px;
`;

export default AddEditUnit;
