// Absolute Imports
import React, { useState } from 'react';
import styled from 'styled-components';

// MUI imports
import {
  Button,
  FormControl,
  FormControlLabel,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  TextField,
} from '@mui/material';

// Relative Imports
import { defaultUser, USERS_URL, validateUser } from './userUtils';
import useAxiosPrivate from '../../../../hooks/useAxiosPrivate';

function AddEditUser(props) {
  const { closeModal, refetchUsers, user } = props;
  const axiosPrivate = useAxiosPrivate();
  const [userState, setUserState] = useState(user || defaultUser);
  const [formErrors, setFormErrors] = useState({});

  const handleFormChange = (e) => {
    e.preventDefault();
    const { value, name } = e.target;
    const error = formErrors[name];
    if (error) {
      setFormErrors({ ...formErrors, [name]: null });
    }

    setUserState({ ...userState, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const body = validateUser(userState);
    if (body.errors) {
      setFormErrors(body.errors);
    } else {
      if (user) {
        await axiosPrivate.put(`${USERS_URL}/${user.id}`, body);
      } else {
        await axiosPrivate.post(USERS_URL, body);
      }
      refetchUsers();
      closeModal();
    }
  };

  return (
    <Container>
      <h2>{user ? 'Edit' : 'Add'} User</h2>
      <br />
      <form onSubmit={handleSubmit}>
        <FormLayout>
          <FormControl
            fullWidth
            sx={{
              gridColumnEnd: 'span 2',
            }}
            error={!!formErrors.user_type}
          >
            <InputLabel id="user-type-switch-label">User Type</InputLabel>
            <Select
              autoFocus
              labelId="user-type-switch-label"
              id="user-type-input"
              value={userState.user_type}
              name="user_type"
              label="User Type"
              onChange={handleFormChange}
            >
              <MenuItem value="internal">Internal</MenuItem>
              <MenuItem value="owner">Owner</MenuItem>
              <MenuItem value="tenant">Tenant</MenuItem>
            </Select>
            {!!formErrors.user_type && (
              <FormHelperText>{formErrors.user_type}</FormHelperText>
            )}
          </FormControl>
          <div className="switch-container">
            <FormControlLabel
              control={
                <Switch
                  name="is_admin"
                  checked={userState.admin_permissions}
                  onChange={handleFormChange}
                  disabled={userState.user_type !== 'internal'}
                />
              }
              label="Is Admin"
            />
          </div>

          <TextField
            label="First Name"
            name="first_name"
            value={userState.first_name}
            onChange={handleFormChange}
            error={!!formErrors.first_name}
            helperText={formErrors.first_name}
            sx={doubleColSx}
          />
          <TextField
            label="Last Name"
            name="last_name"
            value={userState.last_name}
            onChange={handleFormChange}
            error={!!formErrors.last_name}
            helperText={formErrors.last_name}
            sx={doubleColSx}
          />
          <TextField
            label="Email"
            name="email"
            value={userState.email}
            onChange={handleFormChange}
            error={!!formErrors.email}
            helperText={formErrors.email}
            sx={doubleColSx}
          />
          <TextField
            label="Phone Number"
            name="phone_number"
            value={userState.phone_number}
            onChange={handleFormChange}
            error={!!formErrors.phone_number}
            helperText={formErrors.phone_number}
            sx={doubleColSx}
          />
        </FormLayout>
        <ButtonArea className="button-area">
          <Button variant="contained" type="submit">
            Save
          </Button>
          <Button variant="outlined" onClick={closeModal}>
            Cancel
          </Button>
        </ButtonArea>
      </form>
    </Container>
  );
}

const doubleColSx = {
  gridColumnEnd: 'span 3',
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const FormLayout = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 12px;

  .switch-container {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    max-height: 60px;
  }
`;

const ButtonArea = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: auto auto;
  grid-gap: 8px;
  grid-column-start: 1;
  grid-column-end: end;
  margin-top: 12px;
`;

export default AddEditUser;
