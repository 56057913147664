import {
  Checkbox,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  TextField,
} from '@mui/material';
import { convertCase } from '../../utils';

const getSelectOptions = (options) => {
  return options.map((opt) => (
    <MenuItem key={opt} value={opt}>
      {convertCase(opt, 'initialCaps')}
    </MenuItem>
  ));
};

const getInputJsx = (field, changeHandler, valueStateObj) => {
  const { type, key, label, options, rest } = field;

  const keyStrings = convertCase(key);

  switch (type) {
    case 'select':
      return (
        <FormControl fullWidth key={key}>
          <InputLabel id={`${key}-label`}>
            {label || keyStrings.initialCaps}
          </InputLabel>
          <Select
            labelId={`${key}-label`}
            id={`${key}-input`}
            value={valueStateObj[key]}
            name={key}
            label={label || keyStrings.initialCaps}
            onChange={changeHandler}
          >
            {getSelectOptions(options)}
          </Select>
        </FormControl>
      );
    case 'switch':
      return (
        <FormControlLabel
          key={key}
          control={
            <Switch
              name={key}
              checked={valueStateObj[key]}
              onChange={changeHandler}
            />
          }
          label={label || keyStrings.initialCaps}
        />
      );
    case 'checkbox':
      return (
        <FormControlLabel
          control={
            <Checkbox
              key={key}
              name={key}
              checked={valueStateObj[key]}
              onChange={changeHandler}
              {...rest}
            />
          }
          label={label || keyStrings.initialCaps}
        />
      );

    default:
      return (
        <TextField
          key={key}
          type={type}
          label={label || keyStrings.initialCaps}
          name={key}
          value={valueStateObj[key]}
          onChange={changeHandler}
          {...rest}
        />
      );
  }
};

export const formFieldBuilder = (fields, changeHandler, valueStateObj) => {
  const filteredFields = fields.filter((field) => {
    if (field.shouldRender) {
      return field.shouldRender(valueStateObj);
    }
    return true;
  });

  return filteredFields.map((field) =>
    getInputJsx(field, changeHandler, valueStateObj)
  );
};
