export const PROPERTY_URL = '/api/owner/properties';

export const columns = [
  {
    headerName: 'Property',
    field: 'title',
    sortable: true,
    width: 200,
  },
  {
    headerName: 'Owner',
    field: 'owner_name',
    sortable: true,
    width: 200,
  },
  {
    headerName: 'Address',
    field: 'address_line_1',
    width: 200,
  },
  {
    headerName: 'City',
    field: 'city',
    sortable: true,
  },
  // units
  // active
  // repair requests
  // todo items?
];

export const transformPropertyData = (properties) => {
  return properties.map((property) => {
    const owner = property.owner_user;

    return {
      ...property,
      owner_name: `${owner.first_name} ${owner.last_name}`,
    };
  });
};

export const defaultPropertyState = {
  owner_user: 'd47edd9b-8e98-49db-850e-8967dc61eb14',
  title: '',
  address_line_1: '',
  address_line_2: '',
  city: '',
  state: '',
  zip: '',
};

export const propertiesFormFields = [
  {
    key: 'title',
    label: 'Name',
  },
  { key: 'address_line_1' },
  { key: 'address_line_2' },
  { key: 'city' },
  { key: 'state' },
  { key: 'zip' },
];
