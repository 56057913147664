// External Imports
import React, { useEffect, useState } from 'react';
import { Link, Route, Routes } from 'react-router-dom';
import { Button } from '@mui/material';
import styled from 'styled-components';

// Internal Imports
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import NavBar from '../Admin/NavBar';

function Tenant() {
  const axiosPrivate = useAxiosPrivate();
  const [user, setUser] = useState({});

  useEffect(() => {
    const fetchUser = async () => {
      const userResponse = await axiosPrivate.get('/auth/current-user');
      setUser(userResponse.data);
    };
    fetchUser();
  }, [axiosPrivate]);

  return (
    <Container>
      <NavBar />
      <Content>
        <Routes>
          <Route
            path="/"
            element={
              <div>
                <div className="sidebar">
                  <h2>Welcome back, {user.first_name}</h2>
                  <p>What would you like to do?</p>
                  <div className="links">
                    <Link to="/new-application">
                      <Button>Submit New Application</Button>
                    </Link>
                    <Link to="/tenant/my-applications">
                      <Button>See Current Application Status</Button>
                    </Link>
                    <Link to="/tenant/contract">
                      <Button>See my Contract</Button>
                    </Link>
                    <Link to="/tenant/repair-request">
                      <Button>Request Repair</Button>
                    </Link>
                  </div>
                </div>
              </div>
            }
          />
        </Routes>
      </Content>
    </Container>
  );
}

const Container = styled.div`
  width: 100vw;
  height: 100vh;
`;

const Content = styled.main`
  height: calc(100% - 50px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .sidebar {
    text-align: center;
  }
`;

export default Tenant;
