import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import Modal from '@mui/material/Modal';
import { Autocomplete, Button, TextField } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { Box } from '@mui/system';

import useAxiosPrivate from '../../../hooks/useAxiosPrivate';
import {
  columns,
  defaultPropertyState,
  propertiesFormFields,
  PROPERTY_URL,
  transformPropertyData,
} from './propertiesUtils';
import { formFieldBuilder } from '../../Admin/utils';

const fetchProperties = async (axiosPrivate, setProperties) => {
  try {
    const response = await axiosPrivate.get(PROPERTY_URL);
    setProperties(transformPropertyData(response.data));
  } catch (error) {
    console.log('error: ', error);
  }
};

function Properties() {
  const axiosPrivate = useAxiosPrivate();
  const [properties, setProperties] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [propertyState, setPropertyState] = useState(defaultPropertyState);

  const openModal = () => setModalOpen(true);
  const closeModal = () => setModalOpen(false);

  const handleFormChange = (e) => {
    e.preventDefault();
    const { value, name } = e.target;

    setPropertyState({ ...propertyState, [name]: value });
  };

  useEffect(() => {
    fetchProperties(axiosPrivate, setProperties);
  }, [axiosPrivate]);


  return (
    <Container>
      <h1>Properties</h1>

      <TableContainer>
        {properties.length > 0 ? (
          <DataGrid columns={columns} rows={properties} />
        ) : (
          <p>No properties to display</p>
        )}
      </TableContainer>
      <ActionsContainer>
        <Button variant="outlined" onClick={openModal}>
          Add Property
        </Button>
      </ActionsContainer>
      <Modal open={modalOpen} onClose={closeModal}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 500,
            bgcolor: 'white',
            border: '2px solid #000',
            boxShadow: 24,
            p: 4,
          }}
        >
          <FormContainer onSubmit={()=>{}}>
            <h3>Add New Property</h3>

            <Autocomplete
              disablePortal
              id="combo-box-demo"
              options={['Stillwater', 'West Brook Cabins']}
              renderInput={(params) => <TextField {...params} label="Owner" />}
            />
            {formFieldBuilder(
              propertiesFormFields,
              handleFormChange,
              propertyState
            )}
            <div className="button-area">
              <Button variant="contained" type="submit">
                Save
              </Button>
              <Button variant="outlined" onClick={closeModal}>
                Cancel
              </Button>
            </div>
          </FormContainer>
        </Box>
      </Modal>
    </Container>
  );
}

const Container = styled.div``;
const FormContainer = styled.form`
  display: grid;
  grid-template-columns: 100%;
  grid-gap: 12px;

  .button-area {
    width: 100%;
    display: grid;
    grid-template-columns: 50% 50%;
    grid-gap: 8px;
  }
`;

const TableContainer = styled.div`
  height: calc(100vh - 200px);
  margin-top: 12px;
`;

const ActionsContainer = styled.div`
  margin-top: 12px;
`;

export default Properties;
