export const defaultUnitState = {
  title: '',
  description: '',
  address_line_1: '',
  address_line_2: '',
  city: '',
  state: '',
  zip: '',
};

const addressFields = [
  'address_line_1',
  'address_line_2',
  'city',
  'state',
  'zip',
];

export const initialUnitState = (property, unit) => {
  if (unit) {
    return unit;
  }

  const newDefault = { ...defaultUnitState };

  addressFields.forEach((field) => {
    const propertyValue = property[field];
    if (propertyValue) {
      newDefault[field] = propertyValue;
    }
  });

  return newDefault;
};

export const UNIT_URL = '/api/admin/units';

export const validateNewUnit = (unitData) => {
  const errors = {};
  let foundError = false;
  const newUnit = { ...unitData };

  if (!unitData.title) {
    errors.title = 'Unit identifier is required';
    foundError = true;
  }

  if (foundError) {
    newUnit.errors = errors;
  }

  return newUnit;
};
