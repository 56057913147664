import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { DataGrid } from '@mui/x-data-grid';

import useAxiosPrivate from '../../../hooks/useAxiosPrivate';

const columns = [
  {
    headerName: 'ID',
    field: 'id',
    sortable: true,
  },
  {
    headerName: 'Owner',
    field: 'owner_id',
    sortable: true,
  },
  {
    headerName: 'Active',
    field: 'active',
    sortable: true,
  },
];

function Contracts() {
  const [contracts, setContracts] = useState([]);
  const axiosPrivate = useAxiosPrivate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axiosPrivate.get('/api/owner/contracts');
        setContracts(response.data);
      } catch (error) {
        console.log('error: ', error);
      }
    };

    fetchData();
  }, [axiosPrivate]);

  return (
    <Container>
      {contracts.length > 0 ? (
        <DataGrid
          columns={columns}
          rows={contracts}
          pageSize={20}
          rowsPerPageOptions={[5]}
        />
      ) : (
        <p>No contracts to display</p>
      )}
    </Container>
  );
}

const Container = styled.div`
  height: 500px;
`;

export default Contracts;
