import React from 'react';
import styled from 'styled-components';
import { Checkbox, Divider, FormControlLabel, TextField } from '@mui/material';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { convertCase } from '../../utils';
import FileInput from '../../components/FileInput';

const gridGap = '16px';

const TextInput = ({ name, label, state, col, sx, ...rest }) => {
  const { applicationState, handleFormChange, formErrors } = state;

  return (
    <TextField
      name={name}
      label={label || convertCase(name).initialCaps}
      value={applicationState[name]}
      onChange={handleFormChange}
      error={!!formErrors[name]}
      helperText={formErrors[name]}
      sx={{ gridColumnEnd: `span ${col || 1}`, ...sx }}
      {...rest}
    />
  );
};

const GridDivider = ({ children, col, ...rest }) => (
  <Divider
    sx={{
      gridColumnStart: 1,
      gridColumnEnd: `span ${col || 2}`,
    }}
    {...rest}
  >
    {children}
  </Divider>
);

function PersonalSection(props) {
  const { applicationState, formErrors, handleFileChange, handleFormChange } =
    props;

  return (
    <Grid6>
      <TextInput autoFocus name="first_name" state={props} col="2" required />
      <TextInput name="last_name" state={props} col="2" required />
      <DesktopDatePicker
        label="Birth Date"
        inputFormat="MM/dd/yyyy"
        value={applicationState.birth_date}
        onChange={handleFormChange}
        toolbarTitle="Select Birth Date"
        renderInput={(params) => (
          <TextField
            {...{
              sx: { gridColumnEnd: 'span 2' },
              ...params,
            }}
            required
          />
        )}
      />
      <GridDivider col="6">Identifying Info</GridDivider>
      <TextInput name="email" state={props} col="3" required type="email" />
      <TextInput name="phone" state={props} col="3" required />
      <TextInput
        label="Social Security Number"
        name="ssn"
        state={props}
        sx={{ gridColumnStart: 1, gridColumnEnd: 'span 3' }}
        required
      />
      <TextInput
        label="Drivers License / ID #"
        name="drivers_license_number"
        state={props}
        sx={{ gridColumnStart: 1, gridColumnEnd: 'span 3' }}
        required
      />
      <TextInput
        label="Drivers License / ID Issuing State"
        name="drivers_license_state"
        state={props}
        sx={{ gridColumnStart: 1, gridColumnEnd: 'span 3' }}
        required
      />
      <FileContainer>
        <FileInput
          prompt={`Drop ID Photo Here (or click to select) ${
            !!formErrors.idImg ? 'Required' : ''
          }*`}
          onFileChange={handleFileChange}
          fileProp={applicationState.idImg}
          error={!!formErrors.idImg}
        />
      </FileContainer>
    </Grid6>
  );
}

function SecondSection(props) {
  const { applicationState, handleFormChange } = props;
  return (
    <Grid6>
      <GridDivider col="6">Renters Info</GridDivider>
      <TextInput
        autoFocus
        name="other_occupants"
        state={props}
        sx={{
          gridColumnStart: 1,
          gridColumnEnd: 'span 2',
          gridRowStart: 2,
          gridRowEnd: 'span 2',
        }}
        multiline
        minRows={6}
      />
      <TextInput
        multiline
        minRows={6}
        name="notes"
        label="Notes, requests and any special info"
        state={props}
        sx={{
          gridColumnStart: 3,
          gridColumnEnd: 'span 3',
          gridRowStart: 2,
          gridRowEnd: 'span 2',
        }}
      />
      <FormControlLabel
        control={
          <Checkbox
            name="has_pets"
            checked={applicationState.has_pets}
            onChange={handleFormChange}
          />
        }
        label="Has Pets"
      />
      <FormControlLabel
        control={
          <Checkbox
            name="smoker"
            checked={applicationState.smoker}
            onChange={handleFormChange}
          />
        }
        label="Smoker?"
      />
      <GridDivider col="6">Vehicle Info</GridDivider>
      <TextInput name="auto_make" col="2" state={props} />
      <TextInput name="auto_model" col="2" state={props} />
      <TextInput name="auto_year" col="2" state={props} type="number" />
      <GridDivider col="6">Emergency Contact</GridDivider>
      <TextInput name="emergency_contact_name" col="3" state={props} required />
      <TextInput
        name="emergency_contact_relationship"
        col="3"
        state={props}
        required
      />
      <TextInput
        name="emergency_contact_phone"
        col="3"
        state={props}
        required
      />
      <TextInput name="emergency_contact_address" col="3" state={props} />
    </Grid6>
  );
}

function ReferencesSection(props) {
  return (
    <GridContainer>
      <GridDivider>Previous Landlord</GridDivider>
      <TextInput
        autoFocus
        name="previous_landlord_name"
        state={props}
        required
      />
      <TextInput
        multiline
        minRows={4}
        name="previous_address"
        state={props}
        sx={{ gridColumnStart: 2, gridRowEnd: 'span 2' }}
      />
      <TextInput name="previous_landlord_phone" state={props} required />
      <GridDivider>Reference 1</GridDivider>
      <TextInput name="reference_1_name" state={props} required />
      <TextInput name="reference_1_phone" state={props} required />
      <GridDivider>Reference 2</GridDivider>
      <TextInput name="reference_2_name" state={props} />
      <TextInput name="reference_2_phone" state={props} />
      <GridDivider>Employment</GridDivider>
      <TextInput name="current_employer" state={props} required />
      <TextInput name="current_employer_phone" state={props} required />
      <TextInput name="current_employer_address" state={props} />
      <TextInput name="current_employer_position" state={props} />
    </GridContainer>
  );
}

function NotesSection(props) {
  return <GridContainer>PayPal here</GridContainer>;
}

function TermsAndConditions(props) {
  const { applicationState, handleFormChange } = props;

  return (
    <TermsGrid>
      <GridDivider>Terms &amp; Conditions</GridDivider>
      <FormControlLabel
        control={
          <Checkbox
            name="agree"
            checked={applicationState.agree || false}
            onChange={handleFormChange}
          />
        }
        label="I Agree to Terms"
      />
      <div>
        <p>I agree to the terms of this application.</p>
        <p>Which includes</p>
        <ul>
          <li>A background check will be run on the you (the applicant.)</li>
        </ul>
      </div>
    </TermsGrid>
  );
}

const GridContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: ${gridGap};
  width: 100%;
`;

const TermsGrid = styled(GridContainer)`
  grid-template-columns: 1fr 2fr;
`;

const Grid6 = styled(GridContainer)`
  grid-template-columns: repeat(6, 1fr);
`;

const FileContainer = styled.div`
  grid-column-start: 4;
  grid-column-end: 7;
  grid-row-start: 3;
  grid-row-end: 8;
`;

const exports = [
  PersonalSection,
  SecondSection,
  ReferencesSection,
  NotesSection,
  TermsAndConditions,
];

export default exports;
