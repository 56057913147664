import React, { useState } from 'react';

import styled from 'styled-components';
import { Button, TextField } from '@mui/material';

import useAxiosPrivate from '../../../../hooks/useAxiosPrivate';

import FileInput from '../../../../components/FileInput';

const Tag = ({ data, onClick }) => {
  return <TagPill onClick={onClick}>{data}</TagPill>;
};

function UploadAccountDocument(props) {
  const { closeModal, id, refetchData } = props;
  const axiosPrivate = useAxiosPrivate();

  const [file, setFile] = useState();
  const [name, setName] = useState('');
  const [tags, setTags] = useState([]);
  const [tagInput, setTagInput] = useState('');

  const handleNameChange = (e) => {
    setName(e.target.value);
  };
  const handleFileChange = (fileData) => {
    console.log('fileData: ', fileData);
    setFile(fileData);
  };

  const addTag = (e) => {
    e.preventDefault();

    const trimmedValue = tagInput.trim();
    if (trimmedValue && !tags.includes(trimmedValue)) {
      setTags([...tags, trimmedValue]);
    }
    setTagInput('');
  };

  const tagClick = (tag) => {
    console.log('tag: ', tag);

    const newTags = tags.filter((t) => t !== tag);

    setTags(newTags);
  };

  const uploadDoc = async () => {
    const formattedTags = tags.join(',');
    const formData = {
      file,
      title: name,
      tags: formattedTags,
    };

    await axiosPrivate.post(`/api/admin/accounts/${id}/documents`, formData);

    closeModal();
    refetchData();
  };

  return (
    <Container>
      <Head>
        <h2>Upload Document</h2>
      </Head>
      <FormArea>
        <TextField
          autoFocus
          label="Document Name"
          value={name}
          onChange={handleNameChange}
        />
        <FileInputContainer>
          <FileInput
            prompt={`Drop Document Here (or click to select)`}
            fileProp={file}
            onFileChange={handleFileChange}
          />
        </FileInputContainer>
        <p>Tags/Categories</p>
        {tags.length > 0 && (
          <TagContainer>
            {tags.length > 0 &&
              tags.map((t) => (
                <Tag key={t} data={t} onClick={() => tagClick(t)} />
              ))}
          </TagContainer>
        )}
        <TagInputContainer onSubmit={addTag}>
          <TextField
            label="Add Tag"
            value={tagInput}
            onChange={(e) => setTagInput(e.target.value)}
          />
          <Button variant="contained" type="submit" disabled={!tagInput.trim()}>
            Add
          </Button>
        </TagInputContainer>
      </FormArea>
      <ButtonBar>
        <Button variant="contained" onClick={uploadDoc} disabled={!file}>
          Upload
        </Button>
        <Button onClick={closeModal}>Cancel</Button>
      </ButtonBar>
    </Container>
  );
}

const Container = styled.div``;
const Head = styled.div`
  margin-bottom: 18px;
`;

const FormArea = styled.div`
  display: grid;
  grid-gap: 8px;
`;

const FileInputContainer = styled.div`
  height: 200px;
  width: 100%;
`;

const TagInputContainer = styled.form`
  display: grid;
  grid-template-columns: 3fr 1fr;
  grid-gap: 4px;
`;

const TagContainer = styled.div`
  margin-bottom: 8px;
  display: flex;
`;
const TagPill = styled.div`
  padding: 4px 10px;
  margin-right: 4px;
  font-size: 12px;
  border-radius: 20px;
  background-color: navy;
  color: white;
  cursor: pointer;
`;

const ButtonBar = styled.div`
  margin-top: 18px;
  display: flex;
  justify-content: space-between;
`;

export default UploadAccountDocument;
