const camelCase = 'camelCase';
const kebabCase = 'kebabCase';
const snakeCase = 'snakeCase';
const upperSnake = 'upperSnake';
const pascalCase = 'pascalCase';
const initialCaps = 'initialCaps';
const lowerCase = 'lowerCase';
const upperCase = 'upperCase';

const types = [
  camelCase,
  kebabCase,
  snakeCase,
  pascalCase,
  initialCaps,
  lowerCase,
  upperCase,
];

function determineCase(str) {
  str = str.trim();
  const lowerRgx = /[a-z]/;
  const upperRgx = /[A-Z]/;
  const dash = '-';
  const _ = '_';
  const space = ' ';

  const lowerFlag = lowerRgx.test(str);
  const upperFlag = upperRgx.test(str);
  const dashFlag = str.includes(dash);
  const _Flag = str.includes(_);
  const spaceFlag = str.includes(space);

  if (lowerRgx.test(str[0])) {
    if (spaceFlag) {
      return lowerCase;
    } else if (upperFlag) {
      return camelCase;
    } else if (dashFlag) {
      return kebabCase;
    } else if (_Flag) {
      return snakeCase;
    }
  } else {
    if (lowerFlag) {
      if (spaceFlag) {
        return initialCaps;
      }
      return pascalCase;
    } else {
      if (spaceFlag) {
        return upperCase;
      } else if (_Flag) {
        return upperSnake;
      }
    }
  }

  return null;
}

function parseStr(str) {
  const type = determineCase(str);
  const caseSplits = [camelCase, pascalCase];

  if (caseSplits.includes(type)) {
    let outStr = '';
    for (let i = 0; i < str.length; i++) {
      if (/[A-Z]/.test(str[i]) && i > 0) {
        outStr += ` ${str[i].toLowerCase()}`;
      } else {
        outStr += str[i].toLowerCase();
      }
    }

    return outStr.split(' ');
  } else {
    let splitter = ' ';
    switch (type) {
      case kebabCase:
        splitter = '-';
        break;
      case snakeCase:
      case upperSnake:
        splitter = '_';
        break;
      default:
        break;
    }

    return str.toLowerCase().split(splitter);
  }
}

export function convertCase(str, format) {
  const arr = parseStr(str);

  const initCaps = arr.map((w) => w[0].toUpperCase() + w.slice(1));
  const pascalCase = initCaps.join('');
  const camelCase = pascalCase[0].toLowerCase() + pascalCase.slice(1);
  const kebabCase = arr.join('-');
  const snakeCase = arr.join('_');
  const upperSnake = snakeCase.toUpperCase();
  const initialCaps = initCaps.join(' ');
  const lowerCase = arr.join(' ');
  const upperCase = lowerCase.toUpperCase();

  const outputs = {
    camelCase,
    kebabCase,
    snakeCase,
    upperSnake,
    pascalCase,
    initialCaps,
    lowerCase,
    upperCase,
  };

  if (types.includes(format)) {
    return outputs[format];
  }

  return outputs;
}

export const formatToPercent = (n, decimals = 1) => {
  const num = Number(n);
  if (Number(n)) {
    return `${(num * 100).toFixed(decimals)}%`;
  }
};
