import React, { useState } from 'react';
import { Routes, Route } from 'react-router-dom';
import styled from 'styled-components';

import NavBar from './NavBar';
import SideBar from './SideBar';
import subViews from './subViews';
import Account from './subViews/Account';
import Property from './subViews/Property';

const {
  Accounts,
  Application,
  Applications,
  Contracts,
  Dashboard,
  Properties,
  Units,
  Users,
} = subViews;

function Admin() {
  const [collapsedSideBar, setCollapsedSideBar] = useState(false);

  const toggleCollapsedSideBar = () => setCollapsedSideBar(!collapsedSideBar);

  return (
    <Container>
      <NavBar toggleCollapsedSideBar={toggleCollapsedSideBar} />
      <MainPage>
        <SideBar collapsed={collapsedSideBar} />
        <ContentWrapper>
          <Routes>
            <Route path="/" element={<Dashboard />} />
            <Route path="/accounts" element={<Accounts />} />
            <Route path="/accounts/:id" element={<Account />} />
            <Route path="/applications" element={<Applications />} />
            <Route path="/applications/:id" element={<Application />} />
            <Route path="/contracts" element={<Contracts />} />
            <Route path="/properties" element={<Properties />} />
            <Route path="/properties/:id" element={<Property />} />
            <Route path="/units" element={<Units />} />
            <Route path="/users" element={<Users />} />
          </Routes>
        </ContentWrapper>
      </MainPage>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
`;

const MainPage = styled.main`
  display: flex;
  height: calc(100% - 50px);
`;

const ContentWrapper = styled.div`
  padding: 12px;
  width: 100%;
  height: 100%;
  overflow-y: scroll;
`;

export default Admin;
