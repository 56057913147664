import Accounts from './Accounts';
import Application from './Application';
import Applications from './Applications';
import Contracts from './Contracts';
import Dashboard from './Dashboard';
import Properties from './Properties';
import Units from './Units';
import Users from './Users';

const exports = {
  Accounts,
  Application,
  Applications,
  Contracts,
  Dashboard,
  Properties,
  Units,
  Users,
};

export default exports;
