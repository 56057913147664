import React, { useEffect, useRef, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import axios from '../../api/axios';
import useAuth from '../../hooks/useAuth';
import { Alert, AlertTitle, Button, Tab, Tabs, TextField } from '@mui/material';

function Login() {
  const emailRef = useRef();
  const registerEmailRef = useRef();
  const { setAuth } = useAuth();
  const [tabIndex, setTabIndex] = useState(0);
  const [email, setEmail] = useState('');
  const [pw, setPw] = useState('');
  const [pwConfirm, setPwConfirm] = useState('');
  const [errorText, setErrorText] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    emailRef.current.focus();
  }, []);

  useEffect(() => {
    if (tabIndex === 0) {
      emailRef.current.focus();
    } else {
      registerEmailRef.current.focus();
    }
  }, [tabIndex]);

  const selectedLogin = tabIndex === 0;

  const submitLogin = async (e) => {
    e.preventDefault();

    const requestPath = selectedLogin ? '/auth/login' : '/auth/register';

    if (email && pw) {
      if (!selectedLogin && pw !== pwConfirm) {
        return setErrorText('Passwords must match');
      }
      try {
        const response = await axios.post(requestPath, {
          email,
          password: pw,
        });

        const { user, accessToken } = response?.data;

        if (process.env.REACT_APP_DEV === 'true') {
          const { refreshToken } = response?.data;

          localStorage.setItem('jwt', refreshToken);
          setAuth({ accessToken, refreshToken });
        } else {
          setAuth({ accessToken });
        }

        const { admin_permissions, user_type } = user;

        if (admin_permissions && user_type === 'internal') {
          navigate('/admin', { replace: true });
        } else if (user_type === 'owner') {
          navigate('/owner', { replace: true });
        } else {
          navigate('/tenant', { replace: true });
        }
      } catch (error) {
        console.log(error);
        return setErrorText('Login Error!');
      }
    } else {
      return setErrorText('Must fill out email and password');
    }
  };

  const handleTabChange = (p, newValue) => {
    setTabIndex(newValue);
  };

  const a11yProps = (index) => {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  };

  return (
    <LoginContainer>
      <LoginBox>
        <LoginHeader>Northbound Properties</LoginHeader>
        <Tabs value={tabIndex} onChange={handleTabChange} variant="fullWidth">
          <Tab label="Login" {...a11yProps(0)} />
          <Tab label="Create Account" {...a11yProps(1)} />
        </Tabs>
        <TabPanel value={tabIndex} index={0}>
          <LoginForm onSubmit={submitLogin}>
            <TextField
              autoFocus
              inputRef={emailRef}
              id="login-email-input"
              label="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              type="email"
            />
            <TextField
              id="login-pw-input"
              label="Password"
              value={pw}
              onChange={(e) => setPw(e.target.value)}
              type="password"
            />
            <Button type="submit" variant="contained" size="lg">
              Login
            </Button>
          </LoginForm>
          <BottomBar>
            <Link to="/forgot-password">
              <Button variant="outlined">Forgot Password?</Button>
            </Link>
          </BottomBar>
        </TabPanel>
        <TabPanel value={tabIndex} index={1}>
          <LoginForm onSubmit={submitLogin}>
            <TextField
              autoFocus
              inputRef={registerEmailRef}
              id="register-email-input"
              label="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              type="email"
            />
            <TextField
              id="register-pw-input"
              label="Password"
              value={pw}
              onChange={(e) => setPw(e.target.value)}
              type="password"
            />
            <TextField
              id="register-pw-confirm-input"
              label="Confirm Password"
              value={pwConfirm}
              onChange={(e) => setPwConfirm(e.target.value)}
              type="password"
            />
            <Button type="submit" variant="contained" size="lg">
              Create Account
            </Button>
          </LoginForm>
          <BottomBar>
            <p>
              We recommend selecting a strong password that is unique.
              <br />
              Click{' '}
              <a
                href="https://www.pluralsight.com/blog/security-professional/modern-password-guidelines"
                rel="noreferrer noopener"
              >
                HERE
              </a>{' '}
              to learn more.
            </p>
          </BottomBar>
        </TabPanel>
        {errorText && (
          <>
            <br />
            <Alert severity="error" onClose={() => setErrorText('')}>
              <AlertTitle>Error</AlertTitle>
              {errorText}
            </Alert>
          </>
        )}
      </LoginBox>
    </LoginContainer>
  );
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`login-tabpanel-${index}`}
      aria-labelledby={`login-tab-${index}`}
      {...other}
    >
      {children}
    </div>
  );
}

export const LoginContainer = styled.main`
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const LoginBox = styled.div`
  min-width: 490px;
  min-height: 425px;
  padding: 36px;
  border-radius: 8px;
  box-shadow: 2px 2px 5px 1px rgba(0, 0, 0, 0.7);
`;

export const LoginHeader = styled.h1`
  text-align: center;
`;

export const LoginForm = styled.form`
  display: flex;
  flex-direction: column;

  margin: 12px 0;
  margin-top: 20px;

  div {
    margin-bottom: 8px;
  }
  button {
    margin-top: 12px;
  }
`;

const BottomBar = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  padding: 12px 0 0 0;
  margin-top: 16px;
  border-top: 1px solid #888;

  p {
    text-align: center;
    color: #999;
  }
  a {
    text-decoration: none;
    button {
      width: 100%;
    }
  }
`;

export default Login;
