import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';

import useAxiosPrivate from '../../../hooks/useAxiosPrivate';
import { convertCase } from '../../../utils';

function Application() {
  const params = useParams();
  const axiosPrivate = useAxiosPrivate();
  const [application, setApplication] = useState({});

  useEffect(() => {
    const fetchData = async () => {
      const res = await axiosPrivate.get(
        '/api/admin/applications/' + params.id
      );

      setApplication(res.data);
    };

    fetchData();
  }, [axiosPrivate, params]);

  const applicationData = [];

  for (const field in application) {
    const element = application[field];
    applicationData.push({ field, element });
  }

  return (
    <div>
      <h1>Application</h1>
      {applicationData
        .filter((e) => e.element)
        .map((datum) => {
          return (
            <ApplicationLine>
              <div>{convertCase(datum.field).initialCaps}: </div>
              {datum.element}
            </ApplicationLine>
          );
        })}
    </div>
  );
}

const ApplicationLine = styled.div`
  display: flex;
  align-items: flex-end;
  border-bottom: 1px solid #999;
  padding-bottom: 4px;
  margin-bottom: 4px;

  div {
    font-weight: bold;
    width: 300px;
  }
`;

export default Application;
